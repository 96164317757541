(function () {
  'use strict';

  angular
  .module('neo.home.persons.newPerson')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.persons.newPerson', {
      url: '/new-person',
      templateUrl: 'home/persons/new-person/new-person.tpl.html',
      controller: 'NewPersonCtrl',
      controllerAs: 'vm',
      // params: {
      //   entity: undefined,
      //   team: undefined
      // },
      resolve: {
        entities: function (Entitats) {
          return Entitats.query().$promise;
        }
      }
    });
  }
}());
